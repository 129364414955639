import React, { FC } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TrendingPromptsDialog } from './TrendingPromptsDialog';
import {
  deleteTrendingPrompt,
  listTrendingPrompts,
  saveTrendingPromptList,
  updateTrendingPrompt
} from 'core/api/trendingApi';

export interface TrendingPromptsDialogContainerProps {
  open: boolean;
  onAddCLick: () => void;
  onCancel: () => void;
  className?: string;
}

export const TrendingPromptsDialogContainer: FC<
  TrendingPromptsDialogContainerProps
> = ({ open, className, onCancel, onAddCLick }) => {
  const {
    data: trendingPrompts,
    refetch,
    isRefetching: isRefetchingList
  } = useQuery({
    queryKey: ['listTrendingPrompts'],
    queryFn: () => listTrendingPrompts(),
    refetchOnWindowFocus: false,
    select: data => data.sort((a, b) => a.order - b.order)
  });

  const { mutate: onUpdateMutation, isPending: isPendingUpdate } = useMutation({
    mutationFn: updateTrendingPrompt
  });

  const { mutate: onDeleteMutation, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteTrendingPrompt
  });

  const { mutate: onSaveListMutation, isPending: isPendingSaveList } =
    useMutation({
      mutationFn: saveTrendingPromptList
    });

  return (
    <TrendingPromptsDialog
      isSaving={
        isPendingUpdate ||
        isRefetchingList ||
        isPendingDelete ||
        isPendingSaveList
      }
      className={className}
      onAddCLick={onAddCLick}
      trendingPrompts={trendingPrompts}
      onUpdateMutation={onUpdateMutation}
      onDeleteMutation={onDeleteMutation}
      onSaveListMutation={onSaveListMutation}
      open={open}
      onCancel={onCancel}
      onRefetchPrompts={refetch}
    />
  );
};
