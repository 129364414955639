import { api } from './api';
import { TrendingPrompt } from './types';

/**
 * Gets a list of trending prompts.
 *
 * @returns {Promise<TrendingPrompt[]>} A promise that resolves to an array of trending prompts.
 */
export const listTrendingPrompts = async (): Promise<TrendingPrompt[]> => {
  const response = await api.get('/trending');
  return response.data;
};

/**
 * Creates a new trending prompt.
 *
 * @param {TrendingPrompt} trendingPrompt - The trending prompt to create.
 * @returns {Promise<TrendingPrompt>} A promise that resolves to the created trending prompt.
 */
export const createTrendingPrompt = async (
  trendingPrompt: TrendingPrompt
): Promise<TrendingPrompt> => {
  const response = await api.post('/trending', trendingPrompt);
  return response.data;
};

/**
 * Updates an existing trending prompt.
 *
 * @param {TrendingPrompt} trendingPrompt - The trending prompt to update.
 * @returns {Promise<TrendingPrompt>} A promise that resolves to the updated trending prompt.
 */
export const updateTrendingPrompt = async (
  trendingPrompt: TrendingPrompt
): Promise<TrendingPrompt> => {
  const response = await api.put('/trending', trendingPrompt);
  return response.data;
};

/**
 * Deletes a trending prompt.
 *
 * @param {string} id - The ID of the trending prompt to delete.
 * @returns {Promise<void>} A promise that resolves when the trending prompt is deleted.
 */
export const deleteTrendingPrompt = async (id: string): Promise<void> => {
  const response = await api.delete('/trending', { params: { id } });
  return response.data;
};

/**
 * Saves the trending prompts list with order
 * @param {Array<TrendingPrompt>} list - The list of trending prompts to save.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const saveTrendingPromptList = async (
  list: TrendingPrompt[]
): Promise<void> => {
  const response = await api.put('/trending-list', list);
  return response.data;
};
