import React from 'react';
import ReactDOM from 'react-dom/client';
import 'core/utils/tracker';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Notifications, ThemeProvider } from 'reablocks';

import { Auth, AuthRoute } from 'core/Auth';
import { ErrorBoundary } from 'shared/utils/ErrorBoundary';
import { QueryProvider } from 'core/api';

import App from './App';
import Login from 'App/Login';
import Swagger from './App/Swagger';

import './index.css';
import { blackwireTheme } from './shared/utils/Theme/theme';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | Blackwire Labs"
            defaultTitle="Blackwire Labs"
          />
          <ThemeProvider theme={blackwireTheme}>
            <ErrorBoundary>
              <Auth>
                <Notifications timeout={1000} className="notification">
                  <QueryProvider>
                    <Routes>
                      <Route path="login" element={<Login />} />
                      <Route path="swagger" element={<Swagger />} />
                      <Route element={<AuthRoute />}>
                        <Route path="/*" element={<App />} />
                      </Route>
                    </Routes>
                  </QueryProvider>
                </Notifications>
              </Auth>
            </ErrorBoundary>
          </ThemeProvider>
        </HelmetProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>
);
