import { Stack } from 'reablocks';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const Swagger: FC = () => {
  const origin = window.location.origin;

  return (
    <>
      <Helmet>
        <title>Swagger</title>
      </Helmet>
      <Stack className="bg-white flex flex-col w-full overflow-y-auto">
        <SwaggerUI url={`${origin}/api/swagger`} />
      </Stack>
    </>
  );
};
