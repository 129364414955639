import React, { useRef, FC, PropsWithChildren, useEffect, Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { computePosition, offset, shift } from '@floating-ui/dom';

export interface ParagraphProps extends PropsWithChildren {
  highlightedText?: string[];
  highlightColor?: string;
  highlightUser?: any;
}

export const Paragraph: FC<ParagraphProps> = ({
  highlightedText,
  highlightColor,
  highlightUser,
  children
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const popoverRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    async function position() {
      const pos = await computePosition(ref.current, popoverRef.current, {
        placement: 'top',
        middleware: [offset(2), shift()]
      });

      popoverRef.current.style.top = `${pos.y}px`;
      popoverRef.current.style.left = `${pos.x}px`;
    }

    if (ref.current && popoverRef.current) {
      position();
    }
  }, [])

  if (typeof children !== 'string' || !highlightedText?.length) {
    return (
      <p>{children}</p>
    );
  }

  return (
    <p>
      <Highlighter
        searchWords={highlightedText ?? []}
        autoEscape={true}
        textToHighlight={children}
        highlightTag={({ children }) => (
          <mark ref={ref} style={{ background: highlightColor }}>
            {children}
          </mark>
        )}
      />
      {highlightUser?.name && (
        <span
          ref={popoverRef}
          className="rounded p-1 text-xs absolute"
          style={{
            background: highlightColor
          }}
        >
          {highlightUser?.name}
        </span>
      )}
    </p>
  );
};
