import { FC } from 'react';
import { Markdown } from 'shared/data/Markdown';

const MESSAGE = `
## Effective Blackwire.ai Prompting Techniques

### Specify the Context
Example: In the context of GDPR compliance for a SaaS application, provide guidelines for data encryption.

### Include Relevant Regulations
Example: Explain the key requirements of HIPAA for securing patient data in a cloud environment.

### Use Clear and Precise Language
Example: List the steps for implementing multi-factor authentication in a corporate network.

### Define the Desired Output Format
Example: Provide a checklist format for PCI-DSS compliance requirements.

### Incorporate Real-World Scenarios
Example: Describe how to handle a data breach incident involving customer PII under CCPA.

### Request Specific Technical Details
Example: Detail the best practices for configuring a firewall to prevent DDoS attacks.

### Ask for Step-by-Step Guides
Example: Give a step-by-step guide to setting up a secure VPN for remote employees.

### Highlight Common Pitfalls and Solutions**
Example: What are common mistakes in implementing ISO 27001, and how can they be avoided?

### Request Comparative Analyses
Example: Compare the security features of two popular endpoint protection solutions.

### Solicit Expert Recommendations
Example: What are the top five tools recommended for continuous compliance monitoring?
`;

export const Help: FC = () => (
  <Markdown>
    {MESSAGE}
  </Markdown>
);

