import React, { FC } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TrendingPromptsCreateDialog } from './TrendingPromptsCreateDialog';
import {
  createTrendingPrompt,
  listTrendingPrompts
} from 'core/api/trendingApi';

export interface TrendingPromptsCreateDialogContainerProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
}

export const TrendingPromptsCreateDialogContainer: FC<
  TrendingPromptsCreateDialogContainerProps
> = ({ open, className, onCancel }) => {
  const { refetch, isRefetching: isRefetchingList } = useQuery({
    queryKey: ['listTrendingPrompts'],
    queryFn: listTrendingPrompts,
    refetchOnWindowFocus: false,
    select: data => data.sort((a, b) => a.order - b.order)
  });

  const { mutate: onCreateMutation, isPending: isPendingCreate } = useMutation({
    mutationFn: createTrendingPrompt
  });

  return (
    <TrendingPromptsCreateDialog
      isSaving={isPendingCreate || isRefetchingList}
      className={className}
      onCreateMutation={onCreateMutation}
      open={open}
      onCancel={onCancel}
      refetchPrompts={refetch}
    />
  );
};
