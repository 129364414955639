import { Colors } from 'reablocks';

export const colors: Colors = {
  black: '#000000',
  white: '#ffffff',
  red: {
    100: '#FF3333',
    200: '#CC2929',
    300: '#CC0000',
    400: '#980000'
  },
  gray: {
    100: '#D1D1D1',
    200: '#808080',
    300: '#666666',
    400: '#4D4D4D',
    500: '#323333',
    600: '#202121',
    700: '#131414',
    800: '#070808',
    900: '#000101'
  },
  purple: {
    900: '#1b141d',
    800: '#221527',
    700: '#301a3a',
    600: '#3a1e48',
    500: '#432155',
    400: '#4e2667',
    300: '#5f2d84',
    200: '#7938b2',
    100: '#8e4ec6',
    50: '#9d5bd2'
  },
  blue: {
    900: '#0f1720',
    800: '#0f1b2d',
    700: '#10243e',
    600: '#102a4c',
    500: '#0f3058',
    400: '#0d3868',
    300: '#0a4481',
    200: '#0954a5',
    100: '#0091ff',
    50: '#00B4ED'
  },
  green: {
    900: '#0d1912',
    800: '#0f1e13',
    700: '#132819',
    600: '#16301d',
    500: '#193921',
    400: '#1d4427',
    300: '#245530',
    200: '#2f6e3b',
    100: '#46a758',
    50: '#55b467'
  },
  yellow: {
    900: '#1c1500',
    800: '#221a00',
    700: '#2c2100',
    600: '#352800',
    500: '#3e3000',
    400: '#493c00',
    300: '#594a05',
    200: '#705e00',
    100: '#f5d90a',
    50: '#ffef5c'
  },
  orange: {
    900: '#1f1206',
    800: '#2b1400',
    700: '#391a03',
    600: '#441f04',
    500: '#4f2305',
    400: '#5f2a06',
    300: '#763205',
    200: '#943e00',
    100: '#f76808',
    50: '#ff802b'
  },
  slate: {
    900: '#151718',
    800: '#1a1d1e',
    700: '#202425',
    600: '#26292b',
    500: '#2b2f31',
    400: '#313538',
    300: '#3a3f42',
    200: '#4c5155',
    100: '#697177',
    50: '#787f85'
  },
  primary: {
    50: '#E04300',
    100: '#FF3333',
    200: '#CC2929',
    300: '#CC0000',
    400: '#980000'
  },
  secondary: {
    100: '#160612',
    200: '#2400FF',
    300: '#D2B48C',
    400: '#98FB98',
    500: '#00BFFF',
    600: '#DC143C',
    700: '#4260FF',
    800: '#00A284'
  }
};
