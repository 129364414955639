import React, { FC, useCallback } from 'react';
import { useHotkeys, HotkeyShortcuts, MODIFIER_KEY } from 'reakeys';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { List, ListItem } from 'reablocks';
import { Kbd } from 'reablocks';

export const HotkeyCombos: FC = () => {
  const hotkeys = useHotkeys();
  const categories = groupBy(hotkeys, 'category');

  const sorted: { [key: string]: HotkeyShortcuts[] } = Object.keys(
    categories
  ).reduce((prev, cur) => {
    const category = sortBy(categories[cur], 'name');
    const label = cur === 'undefined' ? 'General' : cur;

    return {
      ...prev,
      [label]: category.filter(k => !k.hidden)
    };
  }, {});

  const { General, ...rest } = sorted;
  const others = sortBy(Object.keys(rest || {}));

  const renderKeyCode = useCallback(keyCode => {
    const wrapped = Array.isArray(keyCode) ? keyCode : [keyCode];
    const formatted = wrapped.map(k => k.replace('mod', MODIFIER_KEY));

    return (
      <div>
        {formatted.map((k, i) => (
          <Kbd key={i} className="border border-gray-100" keycode={k} />
        ))}
      </div>
    );
  }, []);

  const renderGroups = useCallback(
    group => {
      if (!sorted[group] || !sorted[group].length) {
        return null;
      }

      return (
        <section key={group}>
          <h3 className="font-bold text-lg mb-md">{group}</h3>
          <List>
            {sorted[group].map(kk => (
              <ListItem
                key={kk.name}
                disableGutters
                disablePadding
                className="mb-md text-white"
                end={renderKeyCode(kk.keys)}
              >
                <label className="text-sm mr-sm opacity-80 font-medium">
                  {kk.name}
                </label>
                {kk.description && (
                  <p className="p-0 text-sm opacity-50">{kk.description}</p>
                )}
              </ListItem>
            ))}
          </List>
        </section>
      );
    },
    [renderKeyCode, sorted]
  );

  return (
    <div className="hot-key-custom-grid">
      {renderGroups('General')}
      {others.map(renderGroups)}
    </div>
  );
};
