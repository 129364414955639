import { PREV_ROUTE, useAuth } from 'core/Auth';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from 'shared/elements/Loader';

export const Login: FC = () => {
  const { isAuthenticated, login, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      login();
    }
  }, [isAuthenticated, login]);

  if (isAuthenticated) {
    let userMeta = { onboarded: false };
    try {
      userMeta = JSON.parse(user.metadata);
    } catch {
      console.error('failed parse userMeta');
    }

    if (!userMeta?.onboarded) {
      const url = `/onboarding${location.search}`;
      return <Navigate to={url} />;
    } else {
      const prevRoute = window.localStorage.getItem(PREV_ROUTE) || '/';
      return <Navigate to={prevRoute} />;
    }
  }

  return (
    <div className="h-screen">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Loader />
    </div>
  );
};
