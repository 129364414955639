import React, { FC, PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import css from './Markdown.module.css';
import { Code } from './Code';
import classNames from 'classnames';
import { remarkCve } from './remarkCve';
import { Paragraph } from './Paragraph';

export interface MarkdownProps extends PropsWithChildren {
  className?: string;
  highlightedText?: string[];
  highlightColor?: string;
  highlightUser?: any;
}

export const Markdown: FC<MarkdownProps> = ({
  children,
  highlightedText,
  className,
  highlightColor = 'red',
  highlightUser,
  ...rest
}) => (
  <ReactMarkdown
    {...(rest as any)}
    className={classNames(css.markdown, className)}
    components={{
      code: Code,
      p: props => (
        <Paragraph
          {...props}
          highlightedText={highlightedText}
          highlightColor={highlightColor}
          highlightUser={highlightUser}
        />
      )
    }}
    remarkPlugins={[gfm, remarkCve]}
  >
    {children}
  </ReactMarkdown>
);

