import { FC } from 'react';
import { Block as ReaBlock, BlockProps as ReaBlockProps, cn } from 'reablocks';

interface BlockProps extends ReaBlockProps {
  corners?: 'top-left' | 'top-right' | 'all' | 'none';
  borderColor?: 'default' | 'white';
  cornersColor?: 'default' | 'white';
  blockClassName?: string;
}

export const Block: FC<BlockProps> = ({
  children,
  className,
  corners = 'top-right',
  borderColor = 'default',
  blockClassName,
  cornersColor,
  ...rest
}) => (
  <ReaBlock labelClassName="text-slate-50" className={blockClassName} {...rest}>
    <div
      className={cn('border border-slate-400 p-1.5 px-2 relative', className, {
        ['block-dot']: corners === 'none',
        ['block-top-left white']: corners === 'top-left',
        ['block-top-right white']: corners === 'top-right',
        ['block-white']: borderColor === 'white',
        ['cornersWhite']: cornersColor === 'white'
      })}
    >
      {corners === 'all' && <div className="block-top" />}
      {children}
      {corners === 'all' && <div className="block-bottom" />}
    </div>
  </ReaBlock>
);
