import { FC, useMemo, PropsWithChildren, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from '@frontegg/react';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { api } from './api';

export const QueryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  const client = useMemo(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false
        },
        mutations: {
          retry: false,
          onError: (error: any) => {
            // TODO: Handle w/ notification
            console.error(`Mutation error: ${error.message}`);
          }
        }
      }
    });

    return queryClient;
  }, []);

  useEffect(() => {
    if (user) {
      // This isn't the best way to handle this but it prevents
      // the interceptor from always having to retry once
      api.defaults.headers['X-API-KEY'] = user.accessToken;

      createAuthRefreshInterceptor(api, (failedRequest) => {
        return new Promise((resolve, _reject) => {
          failedRequest.response.config.headers['X-API-KEY'] = user.accessToken;
          resolve(user.accessToken);
        });
      });
    }
  }, [user]);

  return (
    <QueryClientProvider client={client}>
      {children}
    </QueryClientProvider>
  );
};
