import React, { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from 'shared/elements/Loader';
import { HotkeyDialog } from 'shared/utils/Hotkeys';
import { Nav } from './Nav';

const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import('./NotFound'));
const Registries = lazy(() => import('./Registries'));
const RegistryDetails = lazy(() => import('./RegistryDetails'));
const Billing = lazy(() => import('./Billing'));
const Onboarding = lazy(() => import('./Onboarding'));
const Pricing = lazy(() => import('./Pricing'));

export const App: FC = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col xl:flex-row xl:overflow-y-hidden h-screen max-h-screen">
      <HotkeyDialog />
      {location.pathname !== '/pricing' && <Nav />}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={`/sessions${location.search}`} replace />}
          />
          <Route path="/sessions/:id" element={<Home />} />
          <Route path="/sessions/*" element={<Home />} />
          <Route path="/registries/*" element={<Registries />} />
          <Route path="/registries/:id" element={<RegistryDetails />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
      </Suspense>
    </div>
  );
};
