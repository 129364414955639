import { FronteggThemeOptions } from '@frontegg/react';
import { colors } from 'shared/utils/Theme';

export const themeOptions: FronteggThemeOptions = {
  adminPortal: {
    palette: {
      background: {
        default: 'transparent'
      },
      primary: {
        main: colors.primary['400'],
        active: colors.gray['100'],
        hover: colors.gray['400'],
        contrastText: colors.white
      },
      secondary: {
        active: colors.primary['400']
      },
      success: {
        main: colors.green['100']
      },
      grey: {
        /* Controls greyscales set to 700 ie. Email beneath Full Name and toggle sort hover in Audit Logs */
        '100': colors.gray['100'],
        '200': colors.gray['200'],
        '300': colors.gray['300'],
        '500': colors.gray['500'],
        '700': colors.gray['400'],
        '800': colors.gray['700']
      },
      common: {
        /* Controls default black color setting, ie. for Dark theme mode this is the background of the modal */
        black: 'rgba(26, 29, 30)'
      },
      /* Controls loading UI color */
      loader: colors.red[400],
      text: {
        /* Controls Active color for header row text colors in tables/logs */
        primary: colors.white,
        /* Controls Hover color for  header row text tables/log, and color of logout buttons in Privacy and Security */
        secondary: colors.gray['100']
      }
    },
    pages: {
      audits: {
        content: {
          '.MuiTableCell-root': {
            borderTop: '0 !important'
          },
          '.MuiButton-root': {
            background: 'transparent !important'
          }
        }
      }
    },
    components: {
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: `${colors.red[100]} !important`
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: colors.white,
            '&.Mui-disabled': {
              backgroundColor: `rgba(152, 0, 0, 0.5) !important`
            }
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            /* This fixes the background on svg icons on hover due to setting hover in the primary palette */
            backgroundColor: 'transparent !important'
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            // backgroundColor: `${colors.secondary.purple1} !important`
          }
        }
      },
      MuiStepIcon: {
        text: {
          fill: 'var(--white)'
        }
      },
      MuiStepLabel: {
        label: {
          border: '1px solid red'
        },
        active: {
          color: `${colors.white}`
        },
        disabled: {
          // color: `${colors.secondary.purple5}`
        }
      },
      MuiTypography: {
        styleOverrides: {
          selectOption: {
            color: 'inherit'
          }
        }
      }
    },
    tableTheme: {
      headerContainer: {
        tr: {
          /* This takes care of the scrollbar track color overflow into the table header */
          // backgroundColor: `${colors.secondary.purple1} !important`
        },
        th: {
          /* Controls table header color */
          // backgroundColor: `${colors.secondary.purple1} !important`
        }
      }
    }
  }
};
