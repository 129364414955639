import { User } from '@frontegg/redux-store/auth/interfaces';
import { createContext, useContext } from 'react';
import { UserMeta } from '../api';

// can't import ITenantsResponse type from frontegg so copied it here
export interface Tenant {
  id: string;
  name: string;
  deletedAt: null;
  metadata: any;
  tenantId: string;
  vendorId: string;
  isReseller: boolean;
  createdAt: Date;
  updatedAt: Date;
  address?: string;
  timezone?: string;
  dateFormat?: string;
  timeFormat?: string;
  currency?: string;
  logo?: string;
  logoUrl?: string;
  creatorName?: string;
}

export interface AuthContextProps {
  user: User | null;
  metadata: UserMeta | null;
  activeTenant: Tenant;
  setActiveTenant: (tenant: Tenant | null) => void;
  isAuthenticated?: boolean;
  isLoading?: boolean;
  login: () => void;
  logout: (redirect?: boolean) => void;
  tenants: Array<Tenant>;
}

export const AuthContext = createContext<AuthContextProps>({
  activeTenant: null,
  metadata: null,
  tenants: null,
  setActiveTenant: () => {},
  user: null,
  login: () => undefined,
  logout: () => undefined
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component'
    );
  }

  return context;
};
