import {
  ButtonProps as ReaButtonProps,
  Stack,
  Button as ReaButton
} from 'reablocks';
import { FC } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/btn-arrow.svg';
interface ButtonProps extends ReaButtonProps {
  withArrow?: boolean;
}

export const Button: FC<ButtonProps> = ({ withArrow, children, ...props }) => (
  <Stack dense>
    <ReaButton {...props}>{children}</ReaButton>
    {withArrow && <ArrowIcon />}
  </Stack>
);
