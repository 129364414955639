import { cn } from 'reablocks';
import React, { useRef, FC, useLayoutEffect, PropsWithChildren } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

export interface CodeProps extends PropsWithChildren {
  className?: string;
}

export const Code: FC<CodeProps> = ({ className, children }) => {
  const codeBlockRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    hljs.highlightElement(codeBlockRef.current);
  }, []);

  return (
    <code
      ref={codeBlockRef}
      className={cn(
        'text-sm [background:transparent] [&>span]:whitespace-normal',
        className
      )}
    >
      {children}
    </code>
  );
};
