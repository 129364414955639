import { FC } from 'react';
import { motion } from 'framer-motion';
import { cn } from 'reablocks';

interface LoaderProps {
  containerClassname?: string;
}

export const Loader: FC<LoaderProps> = ({ containerClassname }) => (
  <div
    className={cn(
      'w-full h-full flex justify-center items-center select-none',
      containerClassname
    )}
  >
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="50"
      fill="none"
      animate={{
        opacity: [0.5, 0.7, 0.5],
        scale: [0.9, 1.2, 0.9]
      }}
      transition={{
        duration: 1,
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 0.5
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 0h25.381c5.087 0 9.775 2.752 12.255 7.193a12.253 12.253 0 0 1 1.36 3.796l-4.016.726a8.173 8.173 0 0 0-.908-2.532 9.953 9.953 0 0 0-8.691-5.101h-21.3v13.826l4.961 3.267-2.245 3.41L0 20.107V0Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M34.982 11.715a8.173 8.173 0 0 0-.908-2.532l3.564-1.99a12.269 12.269 0 0 1-1.302 13.852L34.51 23.23l-3.131-2.619 1.826-2.183a8.187 8.187 0 0 0 1.777-6.712Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="m0 15.167 4.08 2.74 22.598 14.884-2.245 3.409L.918 20.712 0 20.108v-4.941Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 50h25.381c5.087 0 9.775-2.752 12.255-7.193a12.534 12.534 0 0 0-2.656-15.512 26.693 26.693 0 0 0-3.246-2.453l-2.203 3.436a22.6 22.6 0 0 1 2.75 2.079 8.452 8.452 0 0 1 1.79 10.46 9.954 9.954 0 0 1-8.69 5.101h-21.3v-4.506H0V50Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#c)"
        fillRule="evenodd"
        d="M4.082 42.432v-16.58H0v16.58h4.082Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#d)"
        fillRule="evenodd"
        d="M29.531 28.278a22.6 22.6 0 0 1 2.75 2.079l2.7-3.062a26.677 26.677 0 0 0-3.247-2.453c-3.354-2.15-8.303-5.376-14.852-9.68l-2.241 3.411c6.551 4.306 11.516 7.542 14.89 9.705Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="35.972"
          x2="32.018"
          y1="12.883"
          y2="21.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="14.668"
          x2="24.49"
          y1="27.169"
          y2="34.056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E70101" />
          <stop offset="1" stopColor="#E70101" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="2.041"
          x2="2.041"
          y1="37.373"
          y2="25.765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="28.572"
          x2="15.562"
          y1="25.383"
          y2="16.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <filter id="glow" x="-120%" y="-120%" width="400%" height="400%">
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0"></feOffset>
          <feGaussianBlur
            result="blurOut"
            in="offOut"
            stdDeviation="10"
          ></feGaussianBlur>
          <feBlend in="SourceGraphic" in2="blurOut" mode="overlay"></feBlend>
        </filter>
      </defs>
    </motion.svg>
  </div>
);
