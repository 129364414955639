import { User } from '@frontegg/redux-store/auth/interfaces';

/**
 * Checks if an email address belongs to the domains 'blackwirelabs.com' or 'goodcode.us'.
 *
 * @param user.
 * @returns A boolean indicating whether the email address ends with '@blackwirelabs.com' or '@goodcode.us'.
 */
export const isGCAndBWTeam = (user: User): boolean => {
  return (
    user.email.endsWith('@blackwirelabs.com') ||
    user.email.endsWith('@goodcode.us')
  );
};
