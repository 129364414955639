import { Button } from 'shared/elements/Button';
import { ReactComponent as ArrowIcon } from 'assets/icons/right-arrow.svg';
import React, { FC, PropsWithChildren } from 'react';
import { cn } from 'reablocks';

export interface OnboardButtonProps extends PropsWithChildren {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  hideArrow?: boolean;
}

export const OnboardButton: FC<OnboardButtonProps> = ({
  children,
  className,
  disabled = false,
  hideArrow = false,
  onClick
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    className={cn(
      'mt-10 border !border-primary-400 rounded-[10px] p-4',
      className
    )}
  >
    {children} {!hideArrow && <ArrowIcon className="ml-4" />}
  </Button>
);
