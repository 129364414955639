import { cn, Stack } from 'reablocks';
import { FC, ReactElement } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface NavButtonProps extends NavLinkProps {
  icon: ReactElement;
}

export const NavButton: FC<NavButtonProps> = ({ icon, ...rest }) => (
  <NavLink {...rest} tabIndex={-1}>
    {({ isActive }) => (
      <Stack
        direction="column"
        alignItems="center"
        className={cn(
          '[&_path]:fill-gray-100 [&>_path]:hover:fill-primary-100 mb-4',
          {
            ['[&_path]:fill-primary-100']: isActive
          }
        )}
      >
        {icon}
      </Stack>
    )}
  </NavLink>
);
