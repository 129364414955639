import { findAndReplace } from 'mdast-util-find-and-replace';

// CREDIT: https://github.com/ninoseki/ioc-extractor/blob/master/src/aux/regexpes.ts#L382
const cveExp = /(CVE-(19|20)\d{2}-\d{4,7})/gi;

export function remarkCve() {
  return (tree, _file) => {
    findAndReplace(tree, [[
      cveExp,
      replaceCve as unknown as any
    ]]);
  };

  function replaceCve(value, id) {
    return [
      {
        type: "link",
        url: `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${id}`,
        children: [
          { type: 'strong', children: [{ type: 'text', value: value.trim() }] }
        ]
      }
    ];
  }
}
