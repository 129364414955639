import { VerticalSpacer } from 'reablocks';
import React, { FC } from 'react';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from 'core/constants';

export const Term: FC = () => (
  <>
    <pre className="relative z-20 custom-l-s custom-l-h whitespace-pre-line">
      {TERMS_OF_SERVICE}
    </pre>
    <VerticalSpacer space="xl" />
    <pre className="relative z-20 custom-l-s custom-l-h whitespace-pre-line">
      {PRIVACY_POLICY}
    </pre>
  </>
);
