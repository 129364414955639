import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AuthProvider } from './AuthContext';
import {
  FronteggProvider,
  useAuth,
  useLoginWithRedirect,
  ContextHolder,
  useTenantsState
} from '@frontegg/react';
import { AuthOptions } from '@frontegg/types/FronteggStoreOptions';
import { ContextOptions } from '@frontegg/types/ContextOptions';
import { themeOptions } from './theme';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/browser';

const authOptions: AuthOptions = {
  keepSessionAlive: true
};

const contextOptions: ContextOptions = {
  baseUrl: import.meta.env.VITE_FRONTEGG_URL,
  clientId: import.meta.env.VITE_FRONTEGG_CLIENT_ID
};

const InternalAuth: FC<PropsWithChildren> = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth();
  const metadata = useMemo(
    () => (user?.metadata ? JSON.parse(user.metadata) : null),
    [user?.metadata]
  );
  const { activeTenant, tenants } = useTenantsState();
  const [currentActiveTenant, setCurrentActiveTenant] = useState(activeTenant);
  const loginWithRedirect = useLoginWithRedirect();

  useEffect(() => {
    setCurrentActiveTenant(activeTenant);
  }, [activeTenant]);

  useEffect(() => {
    async function setupUser() {
      posthog.identify(
        user.id,
        { email: user.email, name: user.name },
        { tenantIds: user.tenantIds }
      );

      if (import.meta.env.PROD) {
        Sentry.withScope(scope =>
          scope.setUser({
            username: user.email,
            name: user.name,
            email: user.email
          })
        );
      }
    }

    if (user) {
      setupUser();
    }
  }, [user]);

  const login = useCallback(async () => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  const logout = useCallback(async () => {
    posthog.reset();
    Sentry.withScope(scope => {
      scope.setUser(null);
    });

    // Clear Cache
    // Reference: https://docs.frontegg.com/docs/react-hosted-login-guide
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  }, []);

  const values = useMemo(
    () => ({
      user,
      metadata,
      login,
      isLoading,
      isAuthenticated,
      logout,
      activeTenant: currentActiveTenant,
      setActiveTenant: setCurrentActiveTenant,
      tenants
    }),
    [
      user,
      metadata,
      login,
      isLoading,
      isAuthenticated,
      logout,
      currentActiveTenant,
      tenants
    ]
  );

  return <AuthProvider value={values}>{children}</AuthProvider>;
};

export const Auth: FC<PropsWithChildren> = ({ children }) => (
  <FronteggProvider
    contextOptions={contextOptions}
    authOptions={authOptions}
    hostedLoginBox
    themeOptions={themeOptions}
  >
    <InternalAuth>{children}</InternalAuth>
  </FronteggProvider>
);
