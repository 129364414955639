import React, { useState, FC } from 'react';
import { Dialog } from 'reablocks';
import { useHotkeys } from 'reakeys';
import { HotkeyCombos } from './HotkeyCombos';

export const HotkeyDialog: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useHotkeys([
    {
      name: 'Hotkey Dialog',
      keys: 'SHIFT+?',
      category: 'General',
      callback: () => setVisible(true)
    }
  ]);

  return (
    <Dialog
      className="bg-gray-800/90"
      innerClassName="border border-solid border-gray-200 rounded-md"
      size="800px"
      header="Hotkeys"
      open={visible}
      onClose={() => setVisible(false)}
    >
      {() => <HotkeyCombos />}
    </Dialog>
  );
};
